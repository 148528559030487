<template>
    <div class="wrapper mt-5">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <the-filter-preset
                        :active-preset="searchOptions.preset"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>
            <message-log-table
                :loading="loading"
                :search-options="searchOptions"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                class="mt-10"
                @sortTable="sortTable"
            />
            <pagination-with-perpage
                :list-lenght="getterLoginMessageLog.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import MessageLogTable from '@/components/MessageLogTable/MessageLogTable.vue';

import {
    ACTION_GET_MESSAGE_LOG_LIST, ACTION_CLEAR_STORE
} from '@/store/modules/messageLog/action-types';

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import { searchFieldsHelper } from '@/services/searchFieldsHelpers';

const {
    mapActions: mapMessageLogAction
} = createNamespacedHelpers('messageLog');

export default {
    name: 'MessageLog',
    components: {
        MessageLogTable,
        TheMainFilter,
        PaginationWithPerpage,
        TheFilterPreset
    },
    data() {
        return {
            loading: true,
            isFirstLoad: true,
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            search_fields: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            // helpers
            isFirstContentLoaded: false
        }
    },
    metaInfo: {
        title: 'Message log'
    },
    computed: {
        ...mapGetters('messageLog', [
            'getterLoginMessageLog',
            'getterLastPage'
        ]),
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'DESC'
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm[ACTION_CLEAR_STORE]()
        })
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        await this.get_search_fields()
        await this.getListMessageLog()
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('message-log')
        },
        ...mapMessageLogAction({
            ACTION_CLEAR_STORE,
            ACTION_GET_MESSAGE_LOG_LIST
        }),
        async getListMessageLog() {
            try {
                this.loading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const promises = [
                    this[ACTION_GET_MESSAGE_LOG_LIST]({ ...this.searchOptions })
                ]
                await Promise.all(promises)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstContentLoaded === false) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getListMessageLog()
            this.isFirstContentLoaded = true
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getListMessageLog()
        },
        async sortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            this.sort.sort_by = e.sortBy[0] || 'clientid'
            this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
            if (!this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...this.sort, page: 1 }
                await this.getListMessageLog()
            }
            this.isFirstLoad = false
        }
    }
}
</script>
