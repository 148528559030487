<template>
    <v-data-table
        :headers="tableHeader"
        item-key="id"
        :items="getterLoginMessageLog"
        :server-items-length="getterTotal"
        :single-select="true"
        :loading="loading"
        hide-default-footer
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        must-sort
        @update:options="$emit('sortTable', $event)"
    >
        <template v-slot:body="{ items }">
            <table-skeleton
                v-if="loading && (items && items.length === 0)"
                :per-page="searchOptions.per_page"
                :column-count="tableHeaderLength"
            />
            <table-loader
                v-if="loading"
            />
            <tbody>
                <template>
                    <!-- {{ items }} -->
                    <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                        class="hovered-row"
                    >
                        <td>
                            <div class="">
                                <p>{{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}.</p>
                            </div>
                        </td>
                        <!-- created -->
                        <td
                            class="td_dates"
                            style="position: sticky; left: 0;z-index: 9; background-color: white;"
                        >
                            <div class="mb-2 d-flex align-center">
                                <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.shortText(item.created_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- avatar -->
                        <td>
                            <div
                                v-if="item.client"
                                class="d-flex align-center"
                            >
                                <div
                                    class="d-flex align-center"
                                >
                                    <avatar-rolles
                                        :firstname="item.client.firstname"
                                        :lastname="item.client.lastname"
                                        :avatar-url="item.client.avatar_url"
                                        :access-status="'client'"
                                        :is-accent="true"
                                        avatar-size="45"
                                    />
                                </div>
                                <div class="ml-4">
                                    <p class="mb-0 font-weight-bold">
                                        {{ item.client.firstname }}
                                        {{ item.client.lastname }}
                                    </p>
                                    <p class="mb-0">
                                        ID: {{ item.clientid }}
                                    </p>
                                </div>
                            </div>
                            <span v-else>-</span>
                        </td>
                        <!-- avatar -->
                        <!-- Domain -->
                        <td class="">
                            <div
                                v-if="item.client"
                            >
                                <domain-favicon
                                    :domain="item.client.domain"
                                />
                            </div>
                            <span
                                v-else
                                class="d-flex justify-center"
                            >
                                -
                            </span>
                        </td>
                        <!-- message -->
                        <td>
                            <p>{{ item.body }}</p>
                        </td>
                        <!-- message -->
                        <!-- phone -->
                        <td>
                            <div class="d-flex align-center">
                                <v-icon
                                    v-if="item.is_incoming"
                                    color="success"
                                    class="mr-4"
                                >
                                    mdi-arrow-left
                                </v-icon>
                                <v-icon
                                    v-else
                                    color="success"
                                    class="mr-4"
                                >
                                    mdi-arrow-right
                                </v-icon>
                                <p
                                    class="ma-1"
                                >
                                    {{ item.phone }}
                                </p>
                            </div>
                        </td>
                        <!-- phone -->
                        <!-- update -->
                        <td class="td_dates">
                            <div class="mb-2 d-flex align-center">
                                <div>{{ $date.dateFromNow(item.updated_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.shortText(item.updated_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- update -->
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/mixins/filtersMixin'

import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

import {
    MESSAGE_LOG_TABLE_HEADER
} from '@/constants/MessageLog'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon.vue';
import InformationTooltip from '@/components/shared/InformationTooltip.vue';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles.vue';

export default {
    name: 'MessageLogTable',
    components: {
        AvatarRolles,
        InformationTooltip,
        DomainFavicon,
        TableSkeleton,
        TableLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        searchOptions: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        sortBy: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        ...mapGetters('messageLog', [
            'getterLoginMessageLog',
            'getterTotal'
        ]),
        tableHeader() {
            return MESSAGE_LOG_TABLE_HEADER
        },
        tableHeaderLength() {
            return MESSAGE_LOG_TABLE_HEADER.length + 1
        }
    }
}
</script>

<style lang="scss">
.payroll-page .v-data-table__wrapper {
    overflow: visible!important;
}

.domain-batch .domain {
  justify-content: normal;
}

.batch-orders{
    background-color: #E3F2FD;
    border-color: #E3F2FD;
}

.online-status{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    box-shadow: 0 0 25px 2px red;
    &--green {
        background: green;
        box-shadow: 0 0 25px 2px green;
    }
}

.cursor-pointer {
    cursor: pointer;
}

</style>
