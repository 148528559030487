export const MESSAGE_LOG_TABLE_HEADER = [
    {
        text: 'In-x',
        sortable: false,
        value: 'index',
        width: '50px'
    },
    {
        text: 'Created at',
        sortable: true,
        value: 'created_at',
        width: '100px'
    },
    {
        text: 'Client',
        sortable: false,
        value: '',
        width: '200px'
    },
    {
        text: 'Domain',
        sortable: false,
        value: '',
        width: '75px',
        align: 'center'
    },
    {
        text: 'Message',
        sortable: false,
        value: '',
        width: '200px'
    },
    {
        text: 'Phone',
        sortable: true,
        value: 'phone',
        width: '100px'
    },
    {
        text: 'Updated at',
        sortable: false,
        value: 'updated_at',
        width: '175px'
    }
]
